<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl class="box">
            <!-- vhod (dostava - glavni vhod) -->
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md5 lg5 xl4>
                    <v-card>
                            <v-card-title>
                                <v-icon left>mdi-boom-gate</v-icon>
                                        <span class="subtitle-1 font-weight-regular application--card--title">Vhodna zapornica</span>
                                        <v-spacer></v-spacer>
                                        <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown" v-if="pg_device_id_1_update_date_time !== null">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon style="margin-right:5px;" v-bind="attrs" v-on="on" :color="deviceUpdateTimestampColor(device_1_update_timestamp_dt)">mdi-information-outline</v-icon>
                                            </template>
                                            <span>Čas podosobitve: {{ pg_device_id_1_update_date_time }}</span>
                                        </v-tooltip>
                                        &nbsp;&nbsp;
                                        <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon style="margin-right:5px;" v-bind="attrs" v-on="on" :color="socTempColor('rpi1')">mdi-thermometer</v-icon>
                                            </template>
                                            <span>Temperatura (SoC): {{ device_1_soc_temp }} {{(device_1_soc_temp !== null && device_1_soc_temp !== "") ? "°C" : "N/A" }}</span>
                                        </v-tooltip>
                                    <v-btn icon @click="card3 = !card3">
                                        <v-icon>{{ card3 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card3"></v-divider>
                            <v-card-text v-show="card3">
                                <div>
                                    <v-list v-if="pg_device_id_1_gate_position != null && pg_device_id_1_gate_constant_rise != null">
                                        <v-list-item>
                                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                                <v-icon size="40" :color="gateConditionColor(pg_device_id_1_gate_position)">mdi-boom-gate</v-icon>
                                            </v-list-item-icon>
                                    
                                            <v-list-item-content >
                                                <v-list-item-title class="body-2 mt-2" >Pozicija zapornice</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-chip
                                                class=""
                                                :color="gateConditionColor(pg_device_id_1_gate_position)"
                                                text-color="white"
                                                >
                                                    <v-avatar left>
                                                        <v-icon>{{ vhodPozicijaZaporniceIcon }}</v-icon>
                                                    </v-avatar>
                                                    {{ gateConditionText(pg_device_id_1_gate_position )}}
                                                </v-chip>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                                <v-icon size="40" :color="gateConstantRiseColor(pg_device_id_1_gate_constant_rise)">mdi-boom-gate-up</v-icon>
                                            </v-list-item-icon>
                                    
                                            <v-list-item-content >
                                                <v-list-item-title class="body-2 mt-2" >Stalni dvig zapornice</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-chip
                                                class=""
                                                :color="gateConstantRiseColor(pg_device_id_1_gate_constant_rise)"
                                                text-color="white"
                                                >
                                                    <v-avatar left>
                                                        <v-icon>{{ gateConstantRiseIcon(pg_device_id_1_gate_constant_rise) }}</v-icon>
                                                    </v-avatar>
                                                    {{ gateConstantRiseText(pg_device_id_1_gate_constant_rise)}}
                                                </v-chip>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-card-text>
                            <v-divider v-show="card3"></v-divider>
                            <v-app-bar
                                v-show="card3"
                                flat
                                color="white"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-car</v-icon>
                                <v-toolbar-title class="body-2">Prehod (dvig - spust zapornice)</v-toolbar-title>
                            </v-app-bar>
                            <v-divider v-show="card3"></v-divider>
                            <v-card-text v-show="card3">
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send({device_id: pg_device_id_1, event_type_id: 1, key: pg_ascent_key_1})" :disabled="loading1 || disabled" :loading="loading1" large class="dvig" block>Dvig</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send({device_id: pg_device_id_1, event_type_id: 3, key: pg_descent_key_1})" :disabled="loading2 || disabled" :loading="loading2" large class="spust" block>Spust</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send({device_id: pg_device_id_1, event_type_id: 2, key: pg_permanent_ascent_key_1})" :disabled="loading3 || disabled" :loading="loading3" large class="stalni_dvig" block>Stalni dvig</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                        </v-card-text>
                    </v-card>
                </v-flex>
                
                <v-flex xs12 sm12 md5 lg5 xl4>
                    <v-card>
                            <v-card-title>
                                <v-icon left>mdi-boom-gate</v-icon>
                                        <span class="subtitle-1 font-weight-regular application--card--title">Izhodna zapornica</span>
                                        <v-spacer></v-spacer>
                                        <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown" v-if="pg_device_id_2_update_date_time !== null">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon style="margin-right:5px;" v-bind="attrs" v-on="on" :color="deviceUpdateTimestampColor(device_2_update_timestamp_dt)">mdi-information-outline</v-icon>
                                            </template>
                                            <span>Čas podosobitve: {{ pg_device_id_2_update_date_time }}</span>
                                        </v-tooltip>
                                        &nbsp;&nbsp;
                                        <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon style="margin-right:5px;" v-bind="attrs" v-on="on" :color="socTempColor('rpi1')">mdi-thermometer</v-icon>
                                            </template>
                                            <span>Temperatura (SoC): {{ device_2_soc_temp }} {{(device_2_soc_temp !== null && device_2_soc_temp !== "") ? "°C" : "N/A" }}</span>
                                        </v-tooltip>
                                    <v-btn icon @click="card1 = !card1">
                                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1">
                                <div>
                                    <v-list v-if="pg_device_id_2_gate_position != null && pg_device_id_2_gate_constant_rise != null">
                                        <v-list-item>
                                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                                <v-icon size="40" :color="gateConditionColor(pg_device_id_2_gate_position)">mdi-boom-gate</v-icon>
                                            </v-list-item-icon>
                                    
                                            <v-list-item-content >
                                                <v-list-item-title class="body-2 mt-2" >Pozicija zapornice</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-chip
                                                class=""
                                                :color="gateConditionColor(pg_device_id_2_gate_position)"
                                                text-color="white"
                                                >
                                                    <v-avatar left>
                                                        <v-icon>{{ vhodPozicijaZaporniceIcon }}</v-icon>
                                                    </v-avatar>
                                                    {{ gateConditionText(pg_device_id_2_gate_position )}}
                                                </v-chip>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                                <v-icon size="40" :color="gateConstantRiseColor(pg_device_id_2_gate_constant_rise)">mdi-boom-gate-up</v-icon>
                                            </v-list-item-icon>
                                    
                                            <v-list-item-content >
                                                <v-list-item-title class="body-2 mt-2" >Stalni dvig zapornice</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-chip
                                                class=""
                                                :color="gateConstantRiseColor(pg_device_id_2_gate_constant_rise)"
                                                text-color="white"
                                                >
                                                    <v-avatar left>
                                                        <v-icon>{{ gateConstantRiseIcon(pg_device_id_2_gate_constant_rise) }}</v-icon>
                                                    </v-avatar>
                                                    {{ gateConstantRiseText(pg_device_id_2_gate_constant_rise)}}
                                                </v-chip>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-card-text>
                            <v-divider v-show="card1"></v-divider>
                            <v-app-bar
                                v-show="card1"
                                flat
                                color="white"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-car</v-icon>
                                <v-toolbar-title class="body-2">Prehod (dvig - spust zapornice)</v-toolbar-title>
                            </v-app-bar>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1">
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send({device_id: pg_device_id_2, event_type_id: 1, key: pg_ascent_key_2})" :disabled="loading4 || disabled" :loading="loading4" large class="dvig" block>Dvig</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send({device_id: pg_device_id_2, event_type_id: 3, key: pg_descent_key_2})" :disabled="loading5 || disabled" :loading="loading5" large class="spust" block>Spust</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send({device_id: pg_device_id_2, event_type_id: 2, key: pg_permanent_ascent_key_2})" :disabled="loading6 || disabled" :loading="loading6" large class="stalni_dvig" block>Stalni dvig</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
            <!--
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md5 lg5 xl4>
                    <v-card>
                            <v-card-title>
                                <v-icon left>mdi-boom-gate</v-icon>
                                        <span class="subtitle-1 font-weight-regular application--card--title">Izhodna zapornica 2</span>
                                        <v-spacer></v-spacer>
                                        <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown" v-if="pg_device_id_3_update_date_time !== null">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon style="margin-right:5px;" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                                            </template>
                                            <span>Čas podosobitve: {{ pg_device_id_3_update_date_time }}</span>
                                        </v-tooltip>&nbsp;
                                    <v-btn icon @click="card2 = !card2">
                                        <v-icon>{{ card2 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card2"></v-divider>
                            <v-card-text v-show="card2">
                                <div>
                                    <v-list v-if="pg_device_id_3_gate_position != null && pg_device_id_3_gate_constant_rise != null">
                                        <v-list-item>
                                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                                <v-icon size="40" :color="gateConditionColor(pg_device_id_3_gate_position)">mdi-boom-gate</v-icon>
                                            </v-list-item-icon>
                                    
                                            <v-list-item-content >
                                                <v-list-item-title class="body-2 mt-2" >Pozicija zapornice</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-chip
                                                class=""
                                                :color="gateConditionColor(pg_device_id_3_gate_position)"
                                                text-color="white"
                                                >
                                                    <v-avatar left>
                                                        <v-icon>{{ vhodPozicijaZaporniceIcon }}</v-icon>
                                                    </v-avatar>
                                                    {{ gateConditionText(pg_device_id_3_gate_position )}}
                                                </v-chip>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                                <v-icon size="40" :color="gateConstantRiseColor(pg_device_id_3_gate_constant_rise)">mdi-boom-gate-up</v-icon>
                                            </v-list-item-icon>
                                    
                                            <v-list-item-content >
                                                <v-list-item-title class="body-2 mt-2" >Stalni dvig zapornice</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-chip
                                                class=""
                                                :color="gateConstantRiseColor(pg_device_id_3_gate_constant_rise)"
                                                text-color="white"
                                                >
                                                    <v-avatar left>
                                                        <v-icon>{{ gateConstantRiseIcon(pg_device_id_3_gate_constant_rise) }}</v-icon>
                                                    </v-avatar>
                                                    {{ gateConstantRiseText(pg_device_id_3_gate_constant_rise)}}
                                                </v-chip>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-card-text>
                            <v-divider v-show="card2"></v-divider>
                            <v-app-bar
                                v-show="card2"
                                flat
                                color="white"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-car</v-icon>
                                <v-toolbar-title class="body-2">Prehod (dvig - spust zapornice)</v-toolbar-title>
                            </v-app-bar>
                            <v-divider v-show="card2"></v-divider>
                            <v-card-text v-show="card2">
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send({device_id: pg_device_id_3, event_type_id: 1, key: pg_ascent_key_3})" :disabled="loading7 || disabled" :loading="loading7" large class="dvig" block>Dvig</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send({device_id: pg_device_id_3, event_type_id: 3, key: pg_descent_key_3})" :disabled="loading8 || disabled" :loading="loading8" large class="spust" block>Spust</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send({device_id: pg_device_id_3, event_type_id: 2, key: pg_permanent_ascent_key_3})" :disabled="loading9 || disabled" :loading="loading9" large class="stalni_dvig" block>Stalni dvig</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm12 md5 lg5 xl4>
                    &nbsp;
                </v-flex>
            </v-layout>-->
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate, parseStatusStr, parseStatusStrV3 } from '@/helpers/utilities'
import moment from 'moment'

export default {


    data: () => ({
        
        card1: true,
        card2: true,
        card3: true,
        card4: true,
        card5: true,
        card6: true,
        loading1: false,
        loading2: false,
        loading3: false,
        loading4: false,
        loading5: false,
        loading6: false,
        loading7: false,
        loading8: false,
        loading9: false,
        loading10: false,
        loading11: false,
        loading12: false,
        disabled: false,

        interval: null,
        devices: [],

        pg_device_id_1: process.env.VUE_APP_DATA_PARKING_GATE_DEVICE_ID_1,
        pg_ascent_key_1: process.env.VUE_APP_DATA_PARKING_GATE_ASCENT_KEY_1,
        pg_descent_key_1: process.env.VUE_APP_DATA_PARKING_GATE_DESCENT_KEY_1,
        pg_permanent_ascent_key_1: process.env.VUE_APP_DATA_PARKING_GATE_PERMANENT_ASCENT_KEY_1,
        pg_device_id_1_gate_position: null,
        pg_device_id_1_gate_constant_rise: null,
        pg_device_id_1_update_date_time: null,

        pg_device_id_2: process.env.VUE_APP_DATA_PARKING_GATE_DEVICE_ID_2,
        pg_ascent_key_2: process.env.VUE_APP_DATA_PARKING_GATE_ASCENT_KEY_2,
        pg_descent_key_2: process.env.VUE_APP_DATA_PARKING_GATE_DESCENT_KEY_2,
        pg_permanent_ascent_key_2: process.env.VUE_APP_DATA_PARKING_GATE_PERMANENT_ASCENT_KEY_2,
        pg_device_id_2_gate_position: null,
        pg_device_id_2_gate_constant_rise: null,
        pg_device_id_2_update_date_time: null,

        pg_device_id_3: process.env.VUE_APP_DATA_PARKING_GATE_DEVICE_ID_3,
        pg_ascent_key_3: process.env.VUE_APP_DATA_PARKING_GATE_ASCENT_KEY_3,
        pg_descent_key_3: process.env.VUE_APP_DATA_PARKING_GATE_DESCENT_KEY_3,
        pg_permanent_ascent_key_3: process.env.VUE_APP_DATA_PARKING_GATE_PERMANENT_ASCENT_KEY_3,
        pg_device_id_3_gate_position: null,
        pg_device_id_3_gate_constant_rise: null,
        pg_device_id_3_update_date_time: null,

        vhod_pozicija_zapornice: 0,
        vhod_stalni_dvig: 1,
        vhod_status_datum: null,
        vhod_status_cas: null,

        izhod_pozicija_zapornice: 1,
        izhod_stalni_dvig: 0,
        izhod_status_datum: null,
        izhod_status_cas: null,

        device_1_update_timestamp: null,
        device_1_update_timestamp_dt: null,
        device_2_update_timestamp: null,
        device_2_update_timestamp_dt: null,
        device_3_update_timestamp: null,
        device_3_update_timestamp_dt: null,

        device_1_soc_temp: null,
        device_2_soc_temp: null,
        device_3_soc_temp: null,

        statuses: [],
        device_1: process.env.VUE_APP_DEVICE_1_UUID,
        device_2: process.env.VUE_APP_DEVICE_2_UUID,
    }),

    watch: {
    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        vhodPozicijaZaporniceIcon() {
            if(this.vhod_pozicija_zapornice == 0) {
                return 'mdi-check'
            }

            return 'mdi-cancel'
        },
    },

    methods: {

        deviceUpdateTimestampColor(deviceUpdateTimestamp) {
            ///window.console.log("Device last update timestamp: ", deviceUpdateTimestamp);
            ///window.console.log("Current timestamp: ", moment().format('YYYY-MM-DD HH:mm:ss'))
            if(deviceUpdateTimestamp !== null) {
                let currentTimestamp = moment().format('YYYY-MM-DD HH:mm:ss')

                let calculation = (new Date(currentTimestamp) - new Date(deviceUpdateTimestamp)) / 1_000;

                ///window.console.log("calculation: ", calculation)

                if(calculation > 45) {
                    return "error"
                }

                return "success"
            }

            return "error";
        },

        socTempColor(device) {
            let color = "success"

            if(device == "rpi1") {
                if(this.device_1_soc_temp > 57 && this.device_1_soc_temp < 67) {
                    color = 'warning'
                }

                if(this.device_1_soc_temp >= 67) {
                    color = 'error'
                }
            }

            if(device == "rpi2") {
                if(this.device_2_soc_temp > 57 && this.device_2_soc_temp < 67) {
                    color = 'warning'
                }

                if(this.device_2_soc_temp >= 67) {
                    color = 'error'
                }
            }

            if(device == "rpi3") {
                if(this.device_3_soc_temp > 57 && this.device_3_soc_temp < 67) {
                    color = 'warning'
                }

                if(this.device_3_soc_temp >= 67) {
                    color = 'error'
                }
            }

            return color;
        },

        gateConditionText(status) {
            if(status == 1) {
                return 'Odprta'
            }

            return 'Zaprta'
        },

        gateConstantRiseText(status) {
            if(status == 1) {
                return 'Aktiven'
            }

            return 'Ni aktiven'
        },

        geteConditionIcon(status) {
            if(status == 1) {
                return 'mdi-check'
            }

            return 'mdi-cancel'
        },

        gateConstantRiseIcon(status) {
            if(status == 1) {
                return 'mdi-check'
            }

            return 'mdi-cancel'
        },

        gateConditionColor(status) {
            if(status == 1) {
                return 'success'
            }

            return 'error'
        },

        gateConstantRiseColor(status) {
            if(status == 1) {
                return 'success'
            }

            return 'error'
        },

        toggleCardContent(card) {
            if(card === 'card1') {
                this.card1 = !this.card1
            }
        },

        send(data) {
            var vm = this
            let payload = {
                topic:"parking-barriers",
                application_uuid: process.env.VUE_APP_UUID,
                payload: data
            };

            let snackbarText = ''

            if(data.key == 'zapornica_176_dvig') {
                this.loading1 = true
                snackbarText = "Dvig vhodne zapornice je uspel."
            }

            if(data.key == 'zapornica_176_spust') {
                this.loading2 = true
                snackbarText = "Spust vhodne zapornice je uspel."
            }

            if(data.key == 'zapornica_176_stalni_dvig') {
                this.loading3 = true
                snackbarText = "Stalni dvig vhodne zapornice je uspel."
            }

            if(data.key == 'zapornica_177_dvig') {
                this.loading4 = true
                snackbarText = "Dvig izhodne zapornice je uspel."
            }

            if(data.key == 'zapornica_177_spust') {
                this.loading5 = true
                snackbarText = "Spust izhodne zapornice je uspel."
            }

            if(data.key == 'zapornica_177_stalni_dvig') {
                this.loading6 = true
                snackbarText = "Stalni dvig izhodne zapornice je uspel."
            }

            /*
            if(data.key == 'zapornica_147_dvig') {
                this.loading7 = true
                snackbarText = "Dvig izhodne zapornice (2) je uspel."
            }

            if(data.key == 'zapornica_147_spust') {
                this.loading8 = true
                snackbarText = "Spust izhodne zapornice (2) je uspel."
            }

            if(data.key == 'zapornica_147_stalni_dvig') {
                this.loading9 = true
                snackbarText = "Stalni dvig izhodne zapornice (2) je uspel."
            }
            */

            if(process.env.VUE_APP_DATA_DEBUG == true) {

                vm.$store.commit('snackbarDisplay', false)
                vm.$store.commit('snackbarMode', 'multi-line')
                vm.$store.commit('snackbarText', snackbarText)
                vm.$store.commit('snackbarTimeout', 7000)
                vm.$store.commit('snackbarDisplay', true)

                this.loading1 = false
                this.loading2 = false
                this.loading3 = false
                this.loading4 = false
                this.loading5 = false
                this.loading6 = false
                this.loading7 = false
                this.loading8 = false
                this.loading9 = false
                this.loading10 = false
                this.loading11 = false
                this.loading12 = false

                vm.disabled = false;

                return;
            }

            this.disabled = true;
            vm.$store.dispatch('PUBLISH', payload)
            .then(response => {
                //window.console.log(response)
                vm.$store.commit('snackbarDisplay', false)
                vm.$store.commit('snackbarMode', 'multi-line')
                vm.$store.commit('snackbarText', snackbarText)
                vm.$store.commit('snackbarTimeout', 7000)
                vm.$store.commit('snackbarDisplay', true)
            })
            .catch(error => {
                vm.$store.commit('snackbarDisplay', false)
                vm.$store.commit('snackbarMode', 'multi-line')
                vm.$store.commit('snackbarText', "Pri upravljanju zapornice je prišlo do napake. Prosimo poskusite ponovno.")
                vm.$store.commit('snackbarTimeout', 7000)
                vm.$store.commit('snackbarDisplay', true)
            })
            .finally(() => {
                this.loading1 = false
                this.loading2 = false
                this.loading3 = false
                this.loading4 = false
                this.loading5 = false
                this.loading6 = false
                this.loading7 = false
                this.loading8 = false
                this.loading9 = false
                this.loading10 = false
                this.loading11 = false
                this.loading12 = false
                setTimeout(() => {
                    vm.disabled = false;
                }, 4000)
            })
        },

        fetchStatuses() {
            let vm = this
            let payload = new Array();

            if(this.device_1 !== null && this.device_1 !== "") {
                payload.push(this.device_1);
            }
            
            if(this.device_2 !== null && this.device_2 !== "") {
                payload.push(this.device_2);
            }

            //window.console.log("payload")
            //window.console.log(payload)

            this.$store.commit('SET_PROGRESS', true)
            this.$store.dispatch('DEVICE_STATUS', {
                devices: payload
            })
            .then(response =>  {
                //window.console.log(response.data)
                //this.prepareDeviceData(response.data)
                vm.setStatuses(response.data.payload);

            })
            .catch(error => {
                window.console.error("### ParkingBarriers@fetchStatuses ###")
                window.console.log(error)
            })
            .finally(() => {
                this.$store.commit('SET_PROGRESS', false)
            })
        },

        setStatuses(payload) {
            let vm = this
            payload.forEach(item => {
                //window.console.log(item)
                if(process.env.VUE_APP_DEVICE_1_UUID == item.device_uuid) {

                    let device1Entrance = parseStatusStrV3(item.status, 1)
                    //let device1Exit = parseStatusStrV3(item.status, 2)

                    vm.pg_device_id_1_gate_position = device1Entrance.gate_position,
                    vm.pg_device_id_1_gate_constant_rise = device1Entrance.gate_constant_rise,
                    vm.pg_device_id_1_update_date_time = item.updated_at_formatted,
                    vm.device_1_update_timestamp = item.updated_at_formatted
                    vm.device_1_update_timestamp_dt = item.updated_at
                    vm.device_1_soc_temp = item.soc_temp

                    // vm.pg_device_id_2_gate_position = device1Exit.gate_position,
                    // vm.pg_device_id_2_gate_constant_rise = device1Exit.gate_constant_rise,
                    // vm.pg_device_id_2_update_date_time = item.updated_at_formatted
                    // vm.device_2_update_timestamp = item.updated_at_formatted
                    // vm.device_2_update_timestamp_dt = item.updated_at
                    // vm.device_2_soc_temp = item.soc_temp

                    //window.console.log(device1Entrance)
                    //window.console.log(device1Exit)
                }

                if(process.env.VUE_APP_DEVICE_2_UUID == item.device_uuid) {
                    let device2Exit = parseStatusStrV3(item.status, 2)

                    vm.pg_device_id_2_gate_position = device2Exit.gate_position,
                    vm.pg_device_id_2_gate_constant_rise = device2Exit.gate_constant_rise,
                    vm.pg_device_id_2_update_date_time = item.updated_at_formatted
                    vm.device_2_update_timestamp = item.updated_at_formatted
                    vm.device_2_update_timestamp_dt = item.updated_at
                    vm.device_2_soc_temp = item.soc_temp
                    
                }
            });
        },

        prepareDeviceData(data) {
            //window.console.log("xxx")
            //window.console.log(data)
            if(Array.isArray(data)) {
                data.forEach(device => {
                    window.console.log("device:")
                    window.console.log(device)
                    if(device.id == 1) {
                        this.vhod_pozicija_zapornice = device.pozicija_zapornice
                        this.vhod_stalni_dvig = device.stalni_dvig
                        this.vhod_status_datum = device.date
                        this.vhod_status_cas = device.time
                    }

                    if(device.id == 2) {
                        this.izhod_pozicija_zapornice = device.pozicija_zapornice
                        this.izhod_stalni_dvig = device.stalni_dvig
                        this.izhod_status_datum = device.date
                        this.izhod_status_cas = device.time
                    }
                })
            }
        }



        
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
        this.fetchStatuses();
        this.interval = setInterval(() => {
            this.fetchStatuses();
        }, process.env.VUE_APP_DEVICE_STATUS_REFRESH_INTERVAL || 8000)

    },

    beforeDestroy() {
        clearInterval(this.interval)
    },

    destroyed() {
        
    }
}

</script>

<style scoped>
    .dvig {
        background: #4ba36b !important;
        color: #fff;
    }

    .spust {
        background: #e79e42 !important;
        color: #fff;
    }

    .stalni_dvig {
        background: #ce5959 !important;
        color: #fff;
    }

</style>